<template>
    <div class="my-login">
        <div class="content">
            <div class="logo">
                <van-image class="logo-img" fit="cover" :src="logoImg" alt=""></van-image>
            </div>
            <div class="username input">
                <input type="number" v-model="form.phone" :placeholder="'请输入'+name+'手机号'">
            </div>
            <div class="password input">
                <input type="password" v-model="form.password" placeholder="请输入密码">
            </div>

            <!-- <van-radio-group v-model="radio" direction="horizontal">
                <van-radio name="1">教练</van-radio>
                <van-radio name="2">人员</van-radio>
            </van-radio-group> -->

            <div class="btn">
                <van-button round type="info" @click="onSubmit" block>登录</van-button>
            </div>

            <div class="clutrue">
               <van-image class="clutrue-img" fit="fill" :src="cultrueImg" alt=""></van-image>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                phone: '',
                password: '',  
            },
            userType: '',
            name: '',
            logoImg: require('@/assets/img/logo.png'),
            cultrueImg: require('@/assets/img/culture.jpg'),
            //radio: '1',
        }
    },
    methods: {
        getToken() {
            if(JSON.stringify(this.$route.query) === '{}'){
                window.location.href = `http://horse.haplive.cn/horse/wechat/wechatApi/register?returnUrl=${window.location.origin}/%23/login`
            }
        },
        onSubmit() {
            if(this.form.phone === '') {
                this.$help.error('请输入手机号')
                return
            }
            if(this.form.password === '') {
                this.$help.error('请输入密码')
                return
            }
            if(this.$help.storeTemp.get('personType') === 1){
                //教练
                this.userType = 'COACH'
            }
            if(this.$help.storeTemp.get('personType') === 2){
                //人员
                this.userType = 'PERSON'
            }
            //跳回来拿到token 再将token存储起来
            this.$help.loading('登录中...')
            this.$api.post('wechat/wechatApi/login-wechat-ajax', {
                mobilePhone: this.form.phone,
                password: this.form.password,
                userType: this.userType
            })
            .then(res=>{
                //18257095483
                // console.log(res)
                if(res.status === "00000"){
                    if(this.$help.storeTemp.get('personType') === 1){
                        this.$help.store.set('COACH', `Bearer ${res.data}`) 
                        this.$router.push('/')
                        this.$help.success('登录成功')
                        this.$help.store.set('coachPhone', this.form.phone)
                        this.$help.store.set('coachPassword', this.form.password)
                    }
                    if(this.$help.storeTemp.get('personType') === 2){
                        this.$help.store.set('PERSON', `Bearer ${res.data}`)
                        this.$router.push('/')
                        this.$help.success('登录成功')
                        this.$help.store.set('personPhone', this.form.phone)
                        this.$help.store.set('personPassword', this.form.password)
                    }
                }
            })
            .finally(this.$help.loaded)
        }
    },
    mounted() {
        if(this.$help.storeTemp.get('personType') === 1){
            //教练
            this.name = '教练'
            //console.log(this.$help.store.get('coachPhone'), this.$help.store.get('coachPassword'))
            if(this.$help.store.get('coachPhone')){
                this.form.phone = this.$help.store.get('coachPhone')
            }
            if(this.$help.store.get('coachPassword')){
                this.form.password = this.$help.store.get('coachPassword')
            }
            
        }
        if(this.$help.storeTemp.get('personType') === 2){
            //会籍顾问
            this.name = '会籍顾问'
             if(this.$help.store.get('personPhone')){
                this.form.phone = this.$help.store.get('personPhone')
            }
            if(this.$help.store.get('personPassword')){
                this.form.password = this.$help.store.get('personPassword')
            }
        }
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .my-login{
        background-color: $main;
        padding: 50px 0 0 0;
        .content{
            background-color: #fff;
            padding: 60px 20px 20px;
            border-radius: 30px 100px 0 0;
            height: calc(100vh - 130px);
            position: relative;
            .van-radio-group{
                margin-top: 30px;
                justify-content: center;
                .van-radio__icon--checked .van-icon{
                    background-color: $main;
                    border-color: $main;
                }
            }
            .logo{
                display: flex;
                justify-content: center;
                &-img{
                    // height: 60px;
                    width: 150px;
                }
            }
            .clutrue{
                padding: 20px;
                &-img{
                    height: 230px;
                }
                margin-top: 20px;
                color: #999;
                
                .title{
                    text-align: center;
                    font-size: 14px;
                    padding-bottom: 5px;
                }
                .text{ 
                    p{
                        font-size: 10px;
                    }
                }
            }
        }
        .input{
            display: flex;
            justify-content: center;
            align-items: center;
            //margin-top: 20px;
            z-index: 888;
            input{
                height: 40px;
                width: 240px;
                padding-left: 15px;
                outline: none;
                border: 1px solid #ebebeb;
                border-radius: 28px;
                background-color: #F4F5F9;
                color: $main;
                margin-top: 30px;
            }
            ::-webkit-input-placeholder{
                color: $main;
                opacity: .4;
            }
            
        }

        .btn{
            margin-top: 30px;
            padding: 0 40px;
            z-index: 888;
            .van-button{
                background-color: $main;
                border: 0;
                z-index: 888;
            }
            
        }
    }
</style>